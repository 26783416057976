$(document).ready(function() {
  $("#viewlet-above-content").hide();
  $("#content-core").insertBefore("#portal-column-two");
  $("#viewlet-below-content").insertAfter("#portal-column-content");
  $("#portal-footer-wrapper").hide();

  $(".tileImage").each(function(index, element) {
    // element == this
    $(this).insertBefore().parent();
  });

  $(".pagination .next").insertAfter(".pagination .last");

  $(".media-block.eq-card").each(function() {
    let $link = $(this).find(".card-link").attr("href");
    $(this).children().wrapAll(`<a class="card-warpper" href=${$link}></a>`);

  });

  $("#left-sidebar-toggle").click(function() {
    $(".menu.sidebar").sidebar("toggle");
  });

  $(".times.icon").click(function() {
    $(".menu.sidebar").sidebar("toggle");
  });

  $(".slider-icon").addClass("menu-toggle");
  $(".slider-icon").attr("for", "menu");

  $("#portal-column-one").hide();

  $("#calendar").addClass("column ten wide").insertBefore("#portal-column-two");

  $(".left.sidebar #portal-globalnav #portaltab-suche a").append(
    `<i class="search icon"></i>`
  );

  $("#viewlet-below-content-body").insertAfter("#portal-column-two");

  $(".visualIEFloatFix a").each(function() {
    $(this)
      .first()
      .not(".summary.url, .documentByLine a")
      .addClass("grid-link");
  });
  $("#parent-fieldname-description").insertAfter(".newsImageContainer");

  let content = document.getElementById("parent-fieldname-title");
  $("#content-core").prepend(content);

  $("#portal-column-one").removeClass("cell width-1:4 position-0 column");
  $("#portal-column-two").removeClass("cell width-1:4 position-3:4");
  $("#LSResult").remove();

  // carousel //
  if ($(".section-front-page").length) {
    $(".prev-silder").on("click", function() {
      plusSlides(-1);
    });

    $(".next-slider").on("click", function() {
      plusSlides(1);
    });

    $(".dot1").click(function(e) {
      e.preventDefault();
      currentSlide(1);
    });
    $(".dot2").click(function(e) {
      e.preventDefault();
      currentSlide(2);
    });
    $(".dot3").click(function(e) {
      e.preventDefault();
      currentSlide(3);
    });

    var slideIndex = 1;

    var myTimer;

    var slideshowContainer;

    window.addEventListener("load", function() {
      showSlides(slideIndex);
      myTimer = setInterval(function() {
        plusSlides(1);
      }, 15000);
    });

    // NEXT AND PREVIOUS CONTROL
    function plusSlides(n) {
      clearInterval(myTimer);
      if (n < 0) {
        showSlides((slideIndex -= 1));
      } else {
        showSlides((slideIndex += 1));
      }

      //COMMENT OUT THE LINES BELOW TO KEEP ARROWS PART OF MOUSEENTER PAUSE/RESUME

      if (n === -1) {
        myTimer = setInterval(function() {
          plusSlides(n + 2);
        }, 15000);
      } else {
        myTimer = setInterval(function() {
          plusSlides(n + 1);
        }, 8000);
      }
    }

    //Controls the current slide and resets interval if needed
    function currentSlide(n) {
      clearInterval(myTimer);
      myTimer = setInterval(function() {
        plusSlides(n + 1);
      }, 8000);
      showSlides((slideIndex = n));
    }

    function showSlides(n) {
      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot-slider");
      if (n > slides.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[slideIndex - 1].style.display = "block";
      dots[slideIndex - 1].className += " active";
    }

    $(".mini-prev-silder").on("click", function() {
      plus(-1);
    });

    $(".mini-next-slider").on("click", function() {
      plus(1);
    });

    // mini caroussel

    var slideIndex = 0;
    showSlides2();

    function showSlides2() {
      var f;
      let n = document.getElementsByClassName("mini-slider");

      for (f = 0; f < n.length; f++) {
        n[f].style.display = "none";
      }
      slideIndex++;
      if (slideIndex > n.length) {
        slideIndex = 1;
      }

      n[slideIndex - 1].style.display = "block";
      setTimeout(showSlides2, 15000); // Change image every 15 seconds
    }
  }

  $("#portal-personaltools").css({ right: "0" });

  $(".tileImage").removeAttr("style");
  $(".tileImage").next("p").css({ "padding-top": "1rem" });
  $("#search-results-wrapper").removeClass("width-full position-0 column");

  $(".listing-item .listing-fields").each(function(index, element) {
    // element == this
    $(element)
      .children("dd.lead_image")
      .insertBefore(element)
      .children("dd.field-Title-tolink");
  });

  $("dd.listing-field.event_end").each(function(index, element) {
    // insert the word (Uhr) in event after the time
    // 27.09.2019, 18:15- 19:30 Uhr
    // We discriminate the two cases by looking into the length

    if ($(this).text().length < 9) {
      var this_html = $(this).html();
      $(this).html(this_html + " Uhr");
    }
  });
  $("dd.listing-field.event_start").each(function(index, element) {
    // Insert the comma in events formatted like this
    // 27.09.2019, 18:15 - 19:30
    //                  ^ <-- the html element we're looping on ends here
    // but leave these ones alone
    // 16.05.2019 - 18.05.2019
    //           ^ <-- the html element we're looping on ends here
    // We discriminate the two cases by looking into the length
    // It's ten, but let's not be scrooge...
    if ($(this).text().length > 11) {
      var this_html = $(this).html();
      $(this).html(this_html.slice(0, 10) + "," + this_html.slice(10));
    }
  });

  $(".media-date.row > div").each(function(index, element) {
    // insert the word (Uhr) in event after the time
    // 27.09.2019, 18:15- 19:30 Uhr
    // We discriminate the two cases by looking into the length
    let text = $(this).text().trim();
    if (text.length > 23) {
      $(this).html(text.slice(0, 10) + "," + text.slice(10) + " Uhr");
    }
  });

  // looping to get all the images which have the same class but not the one in calendar
  $(".image-inline")
    .not(".internal-link .image-inline")
    .each(function(index, element) {
      // get the attr from the image and insert it after the image
      let caption = $(element).attr("title");
      // check if we have a title not to get undefined caption
      if (caption) {
        $(
          `<figcaption class="caption-image">${caption}</figcaption>`
        ).insertAfter(element);
      }
    });

  $(".listing-item").each(function(index, element) {
    // element == this
    $(this).find(".listing-fields").addClass("ui grid stackable");
    let leadImage = $(element).find(
      ".listing-field.field-lead_image-img_mini-tolink"
    );
    let title = $(element).find("dt.listing-field.field-Title-tolink");
    $(leadImage).insertBefore(title);
    let wrp = $(element).find(".listing-fields").children();

    $(wrp).not(leadImage).wrapAll("<div class='wrp column eleven wide' />");
    let lead_image = $(element).find(
      ".listing-field.field-lead_image-img_mini-tolink"
    );
    $(lead_image).wrapAll("<div class='lead-image column five wide' />");
  });
  $("div.tileImage").next().addClass("after-img");
  $("#searchform").hide();
  let portalSearch = $(".template-search #portal-searchbox")
    .clone()
    .addClass("search_tab");
  $(portalSearch).insertAfter("#portal-breadcrumbs");
});

$(document).ready(function() {
  console.log("cookie");
  // Check if the user has interacted with the banner before
  var isBannerHidden = localStorage.getItem('cookieBannerHidden');

  // If the banner has been hidden before, hide it
  if (!isBannerHidden) {
      $('.cookie-banner-container').removeClass("hide-cookie-banner")
  }

  // Add click event handler for accept button
  $('.accept-btn').click(function() {
      // Hide the banner
      $('.cookie-banner-container').addClass("hide-cookie-banner");
      // Set flag in localStorage to remember the user's choice
      localStorage.setItem('cookieBannerHidden', 'true');
  });

  // Add click event handler for reject button
  $('.reject-btn').click(function() {
      // Hide the banner
      $('.cookie-banner-container').addClass("hide-cookie-banner");
      // Set flag in localStorage to remember the user's choice
      localStorage.setItem('cookieBannerHidden', 'true');
  });
});

$(document).ready(function() {
  $('dd.listing-field.field-getJobTitles').each(function() {
    // Get the current text content inside the <dd> element
    let textContent = $(this).text().trim(); // Retrieve text content using jQuery $(this)

    // Remove parentheses (), single quotes ('), and curly braces ({}) from the text content
    textContent = textContent.replace(/[()'{}]/g, ''); // Remove parentheses (), single quotes ('), curly braces ({})

    // Split the modified string into an array of values
    const valuesArray = textContent.split(', '); // Split by comma and space to create an array

    // Join the array into a formatted string
    const formattedText = valuesArray.join(', '); // Join array elements with comma and space

    // Update the text content of the current <dd> element using jQuery $(this)
    $(this).text(formattedText); // Set updated text content using jQuery $(this)
});

  $('dt.listing-field.field-getJobTitles').hide();
});
