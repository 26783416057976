$(function() {
  $("#viewlet-below-content").prepend(
    `<div class='social-network'>
  <a class="external-link" href="https://www.zispotlight.de" target="_blank" title="ZI Spotlight "><img alt="ZI Spotlight" class="image-inline" title="ZI Spotlight" src="bildspeicher/logo_zispotlight"></a>
  <a class="external-link" href="https://www.facebook.com/ZentralinstitutfuerKunstgeschichte" target="_blank" title=""><img alt="Find us on Facebook" class="image-inline" title="Find us on Facebook" src="bildspeicher/facebook_icon"></a><a class="external-link" href="https://www.youtube.com/channel/UCpkl0SdpKTrmUAhWBW0iM4Q" target="_blank" title=""><img alt="Find us on YouTube" class="image-inline" title="Find us on YouTube" src="bildspeicher/find-us-on-youtube"></a><a class="external-link" href="https://www.instagram.com/zimuenchen/" target="_blank" title=""><img alt="Find us on Instagram" class="image-inline" title="Find us on Instagram" src="bildspeicher/logo_instagramm"></a><a class="external-link" href="https://artsandculture.google.com/partner/zentralinstitut-fuer-kunstgeschichte?hl=de" target="_blank" title=""><img alt="Find us on Google Arts &amp; Culture" class="image-inline" title="Find us on Google Arts &amp; Culture" src="bildspeicher/logo_google_arts_and_cultures"></a>
  <a class="external-link" href="https://bsky.app/profile/zikg.eu" target="_blank" title=""><img alt="Find us on Bluesky" class="image-inline" title="Find us on bluesky" src="/bildspeicher/find-us-on-bluesky"/></a>
  <a class="external-link" href="https://www.linkedin.com/company/zentralinstitut-f%C3%BCr-kunstgeschichte/" target="_blank" title=""><img alt="Find us on LinkedIn" class="image-inline" title="Find us on LinkedIn" src="/bildspeicher/linkedin_logo"/></a>
  <div>`
  );
});
