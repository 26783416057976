$(document).ready(function() {
  // footer list
  // footer 4 columns
  $("#viewlet-below-content #portlets-below").addClass("four column row");
  $(".cell").addClass("column");
  // footer grid
  $("#viewlet-below-content").addClass(
    "ui stackable center page grid footer-container"
  );

  // breadcrumbs class semntic UI

  $(".portletItem.odd").addClass("ui inverted link list");
  $(".portletHeader").addClass("ui inverted header "); // use the grid system for the content
  $(".BelowPortletManager5 .portlet .portletItem p:nth-child(3)").addClass(
    "social-network"
  );
  $(".footer-container .social-network").insertBefore(".row.four.column");

  $("#portal-column-content").addClass("ui grid stackable");

  $("#parent-fieldname-text").addClass("column eleven wide");



  $(".nav-items").children().addClass("item");

  $("#c11_place_all, #c11_place_current").addClass("ui radio checkbox");
  $(".faceted_select").addClass("ui dropdown");
  // login button
  $("#login-form #__ac_name").wrap(`<div class="ui input"/>`);
  $("#login-form #__ac_password").wrap(`<div class="ui input"/>`);
  $("#login-form .context ").addClass("ui primary button");

  $(".slider-icon").click(function() {
    $(".menu.sidebar").sidebar("toggle");
  });

  $(".widget-fieldset form .searchButton").addClass("ui primary button");
  $(".widget-fieldset form #c11").wrap(
    `<div class="ui input search-bar-page"/>`
  );

  $("input.searchPage").wrap(`<div class="ui input"/>`);
  $("input.searchPage.searchButton").addClass("ui primary button");

  // kubikat search field
  $("#kubikat-search .form-text").wrap(`<div class="ui input"/>`);
  $("#kubikat-search img").addClass("ui image");

  $(".link-https .pull-right").append(`<i class="angle double right icon"></i>
  `);

  $("#portal-globalnav").parent().addClass("navbar");

  $(".eventDetails.vcard").addClass("ui card");
  $(".eventDetails.vcard h2").addClass("ui header card-header");
  $(".eventDetail").addClass("content");
  $(".eventDetail h3").addClass("ui sub header");

  $(".visualIEFloatFix").each(function(index, value) {
    $(this).children().not("a").wrapAll(`<div class="card-wrapper"/>`);
  });

  $(".visualIEFloatFix").addClass("ui grid stackable");
  $(".grid-link").addClass("column five wide");
  $(".card-wrapper").addClass("column eleven wide");

 
});
