$(function() {
  jquery
    .ajax({
      url: "/@navigation?expand.navigation.depth=5",
      headers: {
        Accept: "application/json",
      },
    })
    .done(function(obj) {
      var getMenuItem = function(itemData) {
        var item = $("<li class='menu-item'>").append(
          $("<a>", {
            href: itemData["@id"],
            html: itemData.title,
            class: "data-item",
            "aria-label": itemData.title,
          })
        );

        if (itemData.items) {
          var subList = $(
            "<div aria-haspopup='true' class='dropdown-content deep-layer menu-nav'></div>"
          );
          $.each(itemData.items, function() {
            subList.append(getMenuItem(this));
          });
          item.append(subList);
        }
        return item;
      };

      var $menu = $("#main-menu");
      $menu.attr("role", "menubar").attr('tabindex', '0');
      $.each(obj.items, function() {
        $menu.append(getMenuItem(this));
      });

      $("#main-menu .dropdown-content:empty").remove();

      $(".deep-layer").each(function(index, element) {
        // element == this
        let text = $(this).parent("li").children("a").clone();

        $(this).prepend(text);
      });

      $(".dropdown-content").children("li").click(function(e) {
        let href = $(this).children("a");
        if (href.attr("href")) {
          let hrefAttr = href.attr("href");
          window.location.href = hrefAttr;
        } // Change This
      });

      $(".dropdown-content").children("a").click(function(e) {
        let hrefA = $(this);
        if (hrefA.attr("href")) {
          let hrefAttrA = hrefA.attr("href");
          window.location.href = hrefAttrA;
        }
      });

      $("#main-menu li")
        .has(".dropdown-content")
        .each(function(index, element) {
          $(this).children("a").removeAttr("href");
        });

      $("#main-menu")
        .children("li")
        .addClass("menu-item")
        .attr("role", "menuitem") // Add role attribute
        // .attr("tabindex", "0")    // Add tabindex attribute
        .find(".dropdown-content")
        .hide();

      $("<li class='suche'><a href='/search'>Suche</a></li>").appendTo(
        "#main-menu"
      );

      $("#main-menu .menu-item")
        .not(".suche")
        .prepend(`<i class="angleicon angle right icon rightCaret"></i>`);

      $("#main-menu .suche")
        .children("a")
        .append(`<i class="search icon rightCaret"></i>`);
        getMenuItem
      $("#main-menu .suche").click(function(e) {
        e.preventDefault();
        let sucheAttr = $(this).children("a").attr("href");
        if (sucheAttr !== undefined) {
          window.location.href = sucheAttr;
        }
      });

      $("#main-menu").children("li").click(function(e) {
        e.preventDefault();
        $(this).children("i").nextAll().show();
      });

      $("#main-menu li")
        .has(".dropdown-content")
        .not("#main-menu .menu-item")
        .each(function(e) {
          $(this).prepend(
            `<i class="angleicon angle right icon rightCaret"></i>`
          );
        });

      $(".dropdown-content")
        .has(".dropdown-content")
        .children("li")
        .click(function(e) {
          $(this).children("i").nextAll().show();
          $(".sidebar").scrollTop(0);
        });

      $("#main-menu .dropdown-content").each(function(e) {
        $(this).prepend(
          `<li class='back menu-item'><a>
                <p>Zurück</p><i class="angleicon angle left icon"></i></a></li>
                <i class="close icon close-nav"></i></a>`
        );
      });
      $("#main-menu").each(function(e) {
        $(this).prepend(
          `<li class='exit menu-item' role='menuitem'><a><i class="close icon"></i></a></li>`
        );
      });

      $("#main-menu").children(".menu-item").last().hide();
      $(".back").click(function(e) {
        let back = $(this);

        $(back).parent().toggleClass("slide-left");
        setTimeout(function() {
          $(back).parent().removeClass("slide-left").hide();
        }, 100);
      });

      $(".close").click(function() {
        $(".menu.sidebar").sidebar("toggle");
      });


      $(".portal-zi").click(function(e) {
        let href = $(this).children("a").attr("href");
        let aTag = document.createElement("a");
        aTag.href = href;
        aTag.click();
      });
    });
});


$(document).ready(function() {
  // Add tabindex attribute to the icon element
  $('#trigger').attr('tabindex', '0');

  // Add event listener for keypress
  $('#trigger').on('keypress', function(event) {
      // Check if the pressed key is Enter (key code 13)
      if (event.which === 13) {
          // Trigger your desired action here
          // For example, you can simulate a click on the icon
          $(this).click();
      }
  });
});

$(document).ready(function() {
  // Function to set tabindex for menu items
  function setTabIndex() {
    $('.data-item').each(function() {
        $(this).attr('tabindex', 0);
    });
}

  // Initial setting of tabindex
  setTabIndex();

  // Add tabindex attribute to newly inserted menu items
  $(document).on('DOMNodeInserted', '.menu-item', function() {
    setTabIndex();
  });

  // Add event listener for keypress on menu items using event delegation
  $(document).on('keypress', '.menu-item', function(event) {
    // Check if the pressed key is Enter (key code 13)
    if (event.which === 13) {
      // Prevent default action to avoid page refresh
      event.preventDefault();

      // Get the link within the menu item and trigger a click
      $(this).find('a')[0].click();
    }
  });
});


$(document).ready(function() {
  // Add event listener for keypress on dynamically added .exit menu items
  $(document).on('keypress', '.exit', function(event) {
      // Check if the pressed key is Enter (key code 13)
      if (event.which === 13) {
          // Prevent default action to avoid page refresh
          event.preventDefault();

          // Find the icon within the menu item and trigger a click on it
          $(this).find('i').click();
      }
  });

  // Function to add tabindex attribute to dynamically added .exit menu items
  function addTabIndex() {
      $('.exit').attr('tabindex', '0');
  }

  // Call the function once on document ready
  addTabIndex();

  // Listen for DOM changes and add tabindex to dynamically added .exit menu items
  $(document).on('DOMNodeInserted', function(e) {
      if ($(e.target).hasClass('exit')) {
          addTabIndex();
      }
  });
});

