$(function() {
  //Loading JSON-formatted data with Ajax and xhr.responseType='json'
  jquery
    .ajax({
      url: "/@navigation?expand.navigation.depth=3",
      headers: {
        Accept: "application/json",
      },
    })
    .done(function(data) {
      var getMenuItem = function(itemData) {
        var item = $(
          `<li class="${"portal-" + itemData["title"].toLowerCase()}">`
        ).append(
          $("<a>", {
            href: itemData["@id"],
            html: itemData["title"],
          })
        );

        if (itemData.items) {
          var subList = $("<div class='dropdown-content'></div>");
          $.each(itemData.items, function() {
            subList.append(getMenuItem(this));
          });
          item.append(subList);
        }
        return item;
      };

      var $menu = $("#portal-globalnav");
      $.each(data.items, function() {
        $menu.append(getMenuItem(this));
      });

      $("#portal-globalnav > li").addClass("navi-list");

      $("#portal-globalnav").children("li").addClass("navi-list");
      $("#portal-globalnav")
        .children("li")
        .has(".dropdown-content")
        .addClass("dropdown");

      $("#portal-globalnav .dropdown-content").each(function(index, element) {
        $(this).children("li").wrapAll("<div role='menu' class='column'><ul role='menu'></ul></div>");
      });

      $("#portal-globalnav .navi-list")
        .has(".dropdown-content ul")
        .children("a")
        .append(`<i class="caret down icon" aria-hidden="false"></i>`);

      $(".dropdown-content .column")
        .find(".dropdown-content .column")
        .removeClass("column");

      $(".dropdown-content .column")
        .find(".dropdown-content")
        .removeClass("dropdown-content")
        .addClass("second-level");
      $(".dropdown-content:empty").remove();

      $(".column").children("ul").addClass("navfulllist");

      $(".second-level a").attr("style", "font-weight: 400 !important");

      $(".more-events")
        .find(".pull-right")
        .attr("href", "/aktuelles/veranstaltungen");

      // Ensure proper ARIA attributes for dropdown toggles
      $("#portal-globalnav .dropdown > a").attr("role", "button");

      // Ensure all ARIA labels and roles are set appropriately
      $("#portal-globalnav").attr("role", "navigation");
      $("#portal-globalnav > li").attr("role", "menuitem");
      $("#portal-globalnav .dropdown-content > .column > ul > li").attr("role", "menuitem");
    });
});

