import "./main";
import "./semantic-ui-classes";
import "./social-media.js";
import "./navbar";
import "./mobile-nav";





$(document).ready(function() {
  $(".mobile.only.row").insertBefore(".logo-mobile");

  $("#middlerow-section").addClass("four column");

  //Adds and removes body class depending on screen width.
  function screenClass() {
    if ($(window).innerWidth() > 200 && $(window).innerWidth() < 991) {
      $(".mobile.only.row").show();
      $(".computer.only.row").hide();

      $("#portal-column-two").attr("style", "width: 100%");

      $(".eventDetails")
        .next()
        .css({ clear: "both", "word-break": "break-word" });
      $("#viewlet-below-content").attr(
        "style",
        "margin-left: 0rem !important; margin-right: 0rem !important"
      );

      $("#content-core").removeClass("column eleven wide");
      $("#portal-column-two").removeClass("column five wide");
      $("#viewlet-below-content #portlets-below")
        .removeClass("four column row")
        .addClass("two column row");
      if ($("table").length) {
        $(".portletWrapper").css({ width: "110%" });
      }
      $("#portal-column-content, #portal-column-two").attr(
        "style",
        "margin-left: 0"
      );
    } else if ($(window).innerWidth() > 992 && $(window).innerWidth() < 1200) {
      $("#middlerow-section").removeClass("four column").addClass("two column");
      $(".mobile.only.row").show();
      $(".computer.only.row").hide();
      $("#portal-searchbox").hide();
      $("#content-core").removeClass("column eleven wide");
      $("#portal-column-two").removeClass("column five wide");
      $("#center-and-left-area #left-area").insertBefore(
        "#center-and-left-area #center-area"
      );
      $("#viewlet-below-content #portlets-below")
        .removeClass("four column row")
        .addClass("two column row");
      $("#portal-column-content, #portal-column-two").attr(
        "style",
        "margin-left: 0"
      );
    } else if ($(window).innerWidth() > 1200) {
      $(".mobile.only.row").hide();
      $(".computer.only.row").show();
      $("#middlerow-section").removeClass("two column").addClass("four column");
      $("#viewlet-below-content #portlets-below")
        .removeClass("two column row")
        .addClass("four column row");
      $("[id^='portaltab']").hide();
      if ($("#content-core").length) {
        $("#content-core").addClass("column eleven wide");
        $("#portal-column-two").addClass("column five wide");
        $("#portal-column-two").insertAfter("#content-core");
      } else {
        $("#content").addClass("column eleven wide");
        $("#portal-column-two").addClass("column five wide");
        $("#portal-column-two").insertAfter("#content");
        $(".main-contanier").addClass("stackable");
        $("#portal-column-two .portletWrapper").addClass("wrapper-taller");
      }
    }
  }
  // Fire.
  screenClass();

  // And recheck when window gets resized
  $(window).bind("resize", function() {
    screenClass();
  });

  // Optimalisation: Store the references outside the event handler:
  if ($(window).innerWidth() < 1024) {
    $("#left-area").insertBefore("#center-area");
    $("#left-area").css({ width: "100%" });
    $(".left-area-js").css({ "margin-left": "0" });
    $("#portal-breadcrumbs").insertAfter(".logo-mobile");
  }
  if (window.matchMedia("(max-width: 1230px)").matches) {
    $("#portal-globalnav").hide();
    $("#portal-globalnav").find(".dropdown-content").hide();
    $(".submenu").hide();
  }
  $("#viewlet-below-content-body").appendTo("#content-core");


  $(".LSBox .searchButton").show();

  $("input.searchPage").attr(
    "style",
    "font-size: 100% !important; border-radius: 0;"
  );
  $("#portal-personaltools").attr("style", "right: 0 !important;");
  $("#portal-personaltools dd").attr("style", "top: 40px !important;");


  $("#viewlet-below-content-title").insertAfter(
    "#parent-fieldname-description"
  );

  if ($("#viewlet-below-content-title").is(":empty")) {
    $("#viewlet-below-content-title").hide();
  }

  if ($(".eventDetails").length) {
    $("#viewlet-below-content-title:not(:empty)").insertBefore(".eventDetails");
    $("#viewlet-below-content-title:not(:empty)").addClass("event-image");
  }
  $("#parent-fieldname-description")
    .addClass("column eleven wide")
    .insertAfter(".documentFirstHeading");

  $("table").wrap("<div class='table-wrapper'/>");

  $("#hcard .row")
    .children()
    .not("div:nth-child(1)")
    .wrapAll("<div class='personal-data-card'/>");

  $(".newsImageContainer").insertAfter("#viewlet-below-content-title");


});
